.modal{
  position: absolute;
  padding: 0 30px 0 30px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30%;
  height: fit-content;
  background: #FFFFFF;
  box-shadow: 0 20px 24px -4px rgba(16, 24, 40, 0.1), 0 8px 8px -4px rgba(16, 24, 40, 0.04);
  border-radius: 12px;
}
.title{
  font-weight: 700 !important;
  font-size: 24px !important;
  padding: 20px 0 20px 0;
}
.inputTitle{
  font-weight: 500 !important;
  font-size: 12px !important;
  margin-bottom: 5px !important;
}
.create_user_item{
  margin: 0 0 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  flex-wrap: nowrap;
}
.switch{
  margin: 10px 0 0 0;
  display: flex;
  align-items: center;
}
.type_proxy{
  display: inline-block;
  text-transform: lowercase;
  font-size: 12px;
  font-weight: 500;
  width: fit-content;
  padding: 2px 12px;
  background: #EEF4FF;
  border-radius: 4px;
  color: #3538CD;
}
.type_proxy::first-letter{
  text-transform: uppercase;
}
.type_push{
  display: inline-block;
  text-transform: lowercase;
  font-size: 12px;
  font-weight: 500;
  width: fit-content;
  border-radius: 4px;
  padding: 2px 12px;
  background: #FFFAEB;
  color: #DCAF10;
}
.type_push::first-letter{
  text-transform: uppercase;
}
.type_processing{
  display: inline-block;
  text-transform: lowercase;
  font-size: 12px;
  font-weight: 500;
  width: fit-content;
  border-radius: 4px;
  padding: 2px 12px;
  background: #F1FFFF;
  color: #10A1C0;
}
.type_processing::first-letter{
  text-transform: uppercase;
}

.module{
  font-size: 16px !important;
}

.switch{
  margin: 10px 0 0 0;
  display: flex;
  align-items: center;
}

.name{
  color: #272626;
  font-weight: 700 !important;
  font-size: 26px !important;
  text-transform: capitalize;
}
