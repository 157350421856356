.wrapper-source-list{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.title-sourse-list {
    display: flex;
    align-items: center;
    width: 90%;
}
.title-storage-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-self: flex-start;
    width: 89%;
}
.item-source-actions{
    display: flex;
    justify-content: center;
}
.title-storage-list > p {
    font-size: 20px;
    font-weight: 600;
}
.title-sourse-list > p {
    font-size: 20px;
    font-weight: 600;
}
.item-source-list {
    display: flex;
    align-items: center;
    width: 90%;
    margin: 5px 0;
    cursor: pointer;
}
.item-source-list:hover {
    background-color: rgba(176, 176, 176, 0.07);
    border-radius: 5px;
}
.item-source-list > p {
    font-size: 17px;
}
.title-sourse {
    width: 16%;
    font-size: 20px;
    font-weight: 600;
}
.item-source {
    width: 16%;
}
.status {
    text-align: center;
    width: 8%;
}
.actions{
    text-align: center;
    width: 15%;
}
.activeStatus {
    display: inline-block;
    font-size: 17px;
    border: 1px solid rgb(65, 146, 65);
    width: 85.33px;
    border-radius: 5px;
    padding: 8px 12px;
    color: rgb(65, 146, 65);
}
.inactiveStatus {
    display: inline-block;
    font-size: 17px;
    border: 1px solid rgb(146, 65, 65);
    width: 85.33px;
    border-radius: 5px;
    padding: 8px 12px;
    color: rgb(146, 65, 65);
}
.ActiveStatus {
    display: inline-block;
    font-size: 13px;
    width: 85.33px;
    border-radius: 5px;
    padding: 3px;
    color: white;
    background-color: rgb(120, 201,81);
}
.InactiveStatus {
    display: inline-block;
    font-size: 13px;
    border: 1px solid rgb(213, 57, 57);
    width: 85.33px;
    border-radius: 5px;
    padding: 3px;
    color: white;
    background-color: rgb(213, 57, 57);
}
.apiKey {
    width: 30%;
}
.center {
    text-align: center;
}
.icon{
    width: fit-content;
    margin: -24px;
}
.wrapper-dashboard-list{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.title-dashboard-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
}
.title-dashboard-list > p {
    font-size: 17px;
    font-weight: 600;
}
.title-dashboard {
    width: 30%;
    font-size: 20px;
    font-weight: 600;
}
.item-dashboard-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin: 20px 0;
}
.item-dashboard-list > p {
    font-size: 17px;
}
.item-dashboard {
    width: 30%;
}
.wrapper-headerDashboard-text {
    width: 90%;
    margin: 15px auto;
}
.wrapper-headerDashboard-text > .headerDashboard-text {
    font-size: 22px;
    font-weight: 600;
    margin: 30px 0;
}
.wrapper-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90vh;
}
.table-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30vh;
}
 