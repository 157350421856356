.wrapperNav {
    top: 0;
    position: sticky;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #272626;
    height: 100vh;
    font-family: 'DM Sans', sans-serif;
}

.wrapperLogo {
    width: 100%;
    height: 140px;
    padding: 32px 32px;
}

.menu-item {
    display: flex;
    align-items: center;
    padding: 11px 0 11px 32px;
    cursor: pointer;
    color: #7E7E7E;
    margin-bottom: 24px;
}

.menu-item-text {
    width: 100%;
    padding: 0 0 0 15px;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 26px;
}

.menu-item:hover {
    color: #ffffff;
}

.menu-item:hover .wrapper-icon svg{
    color: #ffffff;
    fill: #ffffff;

}

.active:hover {
    color: #ffffff;
}

.active::after {
    content: "\A";
    display: block;
    position: relative;
    background-color: #ffffff;
    color: #429430;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 5px solid #429430;
    top: 0;
    left: 7px;
}

.active {
    background: linear-gradient(90deg, #429430 -16.96%, rgba(133, 255, 49, 0.7) 33.31%, rgba(4, 4, 4, 0) 125%);
    color: #ffffff;
}

.activeAdmin {
    background: linear-gradient(90deg, #429430 -16.96%, rgba(133, 255, 49, 0.7) 33.31%, rgba(4, 4, 4, 0) 125%);
    color: #ffffff;
}

.active svg {
    fill: #ffffff;
}

.wrapper-icon {
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo {
    display: inline-block;
    font-size: 20px;
    font-weight: 700;
}

.green {
    color: #429430;
}

.white {
    color: #ffffff;
}
.ItemContainer{
    display: flex;
    flex-direction: column;
    background-color: #272626;
    height: 100%;
}

/*.ItemContainer > a:last-child{*/
/*    display: flex;*/
/*    align-items: center;*/
/*    padding: 11px 0 11px 32px;*/
/*    margin-top: auto;*/
/*    cursor: pointer;*/
/*    margin-bottom: 24px;*/
/*}*/
