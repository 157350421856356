.loginPageWrapper{
  position: absolute;
  padding: 0 30px 0 30px;
  overflow: hidden;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30%;
  height: 500px;
  background: #FFFFFF;
  box-shadow: 0 20px 24px 12px rgba(16, 24, 40, 0.1), 0 8px 8px -4px rgba(16, 24, 40, 0.04);
  border-radius: 12px;
}
.inputTitle{
  font-weight: 500 !important;
  font-size: 12px !important;
  margin-bottom: 5px !important;
}
.create_source_item{
  margin: 0 0 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  flex-wrap: nowrap;
}
.title{
  display: flex;
  justify-content: center;
  font-weight: 700 !important;
  font-size: 48px !important;
  padding: 30px 0 60px 0;
}
.header{
  background-color: #272626;
  height: 30px;
  margin-left: -30px;
  margin-right: -30px;
  display: flex;
  justify-content: center;
}
.logo {
  display: inline-block;
  font-size: 20px;
  font-weight: 700;
}

.green {
  color: #429430;
}

.white {
  color: #ffffff;
}
